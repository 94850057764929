import Packery from 'packery';
import imagesLoaded from 'imagesloaded';

function initMasonry() {
    const grid = document.querySelector('.gallery_thumbs');
    if (!grid) return;

    const packery = new Packery(grid, {
        itemSelector: '.gallery_item',
        gutter: 10  ,
        percentPosition: true

    });
        window.packery = packery;

    imagesLoaded( grid ).on( 'progress', function() {
        // layout Masonry after each image loads
        packery.layout();
    });

    packery.remove_item = function(item_id) {
        const item = document.getElementById("item_" + item_id);
        packery.remove(item);
        packery.layout();
    }
}

addEventListener("turbo:before-stream-render", ((event) => {
    const fallbackToDefaultActions = event.detail.render

    event.detail.render = function (streamElement) {
        if (streamElement.action == "destroy") {
            console.log("Destroying item", streamElement.dataset.itemId);
            const item_id = streamElement.dataset.itemId;
            packery.remove_item(item_id);
        } else {
            fallbackToDefaultActions(streamElement)
        }
    }
}))


document.addEventListener('DOMContentLoaded', initMasonry);
